import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBIpKNj4RagSN5lyHqwlKsT2GthOQvfITI",
  authDomain: "invoice-management-tool.firebaseapp.com",
  databaseURL: "https://invoice-management-tool-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "invoice-management-tool",
  storageBucket: "invoice-management-tool.appspot.com",
  messagingSenderId: "29769281917",
  appId: "1:29769281917:web:f5c2331d8750ece54e1c14",
  measurementId: "G-9BTKL87QMT"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase();

export { app, auth, db, signInWithEmailAndPassword, onValue, ref, set };