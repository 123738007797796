import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table, Modal, } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from '../../components/Common/TableContainer';

//Import Image
import logo from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { getInvoiceDetail as onGetInvoiceDetail } from "../../store/invoices/actions";
//redux
import { useDispatch } from "react-redux";
import parse from 'html-react-parser';

import { ref, db, onValue, set } from "../../firebase";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import "./style.css";

const InvoiceDetail = props => {

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);
  const [printing, setprinting] = useState(false);
  const [status, setstatus] = useState("");

  //meta title
  document.title = "Invoice Detail | Invoice Management System";

  const dispatch = useDispatch();

  const [client, setclient] = useState('');
  const [clientaddress2, setclientaddress] = useState('');

  const [company, setcompany] = useState('');
  const [companyaddress2, setcompanyaddress2] = useState('');

  const [bank, setbank] = useState('');
  const [bankdetails, setbankdetails] = useState('');

  const [labels, setlabels] = useState('');

  const [invoiceDetail, setInvoiceDetails] = useState({
    invoiceno: "2023-1",
    issuedate: "",
    issuedatedisplay: "",
    duedate: "",
    duedatedisplay: "",
    note: "Order Summary",
    item: [],
    currency: "USD",
    total: "",
  });

  useEffect(()=>{
    let d = new Date();
    let newdate = d.getDate().toString();
    if(d.getMonth() == 0){
      newdate = newdate + ". Jan ";
    }else if(d.getMonth() == 1){
      newdate = newdate + ". Feb ";
    }else if(d.getMonth() == 2){
      newdate = newdate + ". Mar ";
    }else if(d.getMonth() == 3){
      newdate = newdate + ". Apr ";
    }else if(d.getMonth() == 4){
      newdate = newdate + ". May ";
    }else if(d.getMonth() == 5){
      newdate = newdate + ". Jun ";
    }else if(d.getMonth() == 6){
      newdate = newdate + ". Jul ";
    }else if(d.getMonth() == 7){
      newdate = newdate + ". Aug ";
    }else if(d.getMonth() == 8){
      newdate = newdate + ". Sep ";
    }else if(d.getMonth() == 9){
      newdate = newdate + ". Oct ";
    }else if(d.getMonth() == 10){
      newdate = newdate + ". Nov ";
    }else if(d.getMonth() == 11){
      newdate = newdate + ". Dec ";
    }
    newdate = newdate + d.getFullYear().toString();
    setInvoiceDetails({
      ...invoiceDetail,
      issuedatedisplay: newdate,
      duedatedisplay: newdate,
    });
  },[]);

  const columns = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: 'invoiceno',
      },
      {
        Header: 'Client',
        accessor: 'client'
      },
      {
        Header: 'Address',
        accessor: 'address'
      },
      {
        Header: 'Action',
        accessor: 'action'
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: 'invoiceno',
      },
      {
        Header: 'Client',
        accessor: 'company'
      },
      {
        Header: 'Address',
        accessor: 'address'
      },
      {
        Header: 'Action',
        accessor: 'action'
      },
    ],
    []
  );

  const columns3 = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: 'invoiceno',
      },
      {
        Header: 'Bank',
        accessor: 'bank'
      },
      {
        Header: 'Details',
        accessor: 'details'
      },
      {
        Header: 'Action',
        accessor: 'action'
      },
    ],
    []
  );

  const columns4 = useMemo(
    () => [
      {
        Header: 'Sr. No.',
        accessor: 'invoiceno',
      },
      {
        Header: 'Label',
        accessor: 'label'
      },
      {
        Header: 'Action',
        accessor: 'action'
      },
    ],
    []
  );

  const [isaddclient, setaddclient] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientname, setclientname] = useState('');
  const [clientaddress, setClientaddress] = useState('');

  const [isaddcompany, setaddcompany] = useState(false);
  const [companys, setcompanys] = useState([]);
  const [companyname, setcompanyname] = useState('');
  const [companyaddress, setcompanyaddress] = useState('');

  const [isaddbank, setaddbank] = useState(false);
  const [banks, setbanks] = useState([]);
  const [bankname, setbankname] = useState('');
  const [bankaddress, setbankaddress] = useState('');

  const [islabel, setislabel] = useState(false);
  const [alllabels, setalllabels] = useState([]);
  const [newlabel, setnewlabel] = useState('');

  const [st, setSt] = useState(0);
  const [invoiceinput, setinvoiceinput] = useState(false);
  const [noteinput, setnoteinput] = useState(false);
  const [currencyinput, setcurrencyinput] = useState(false);

  const params = props.router.params;
  useEffect(() => {
    if (params && params.id) {
      const starCountRef5 = ref(db, 'invoices/'+params.id);
      onValue(starCountRef5, (snapshot) => {
        const cs = snapshot.val();
        setclient(cs.client);
        setclientaddress(cs.clientaddress2);
        setcompany(cs.company);
        setcompanyaddress2(cs.companyaddress2);
        setbank(cs.bank);
        setbankdetails(cs.bankdetails);
        setlabels(cs.labels);
        setInvoiceDetails(cs.invoiceDetail);
        setstatus(cs.status);
      });
    } else {
    }
    const starCountRef = ref(db, 'clients');
    onValue(starCountRef, (snapshot) => {
      const cs = snapshot.val();
      let temp = [];
      let keys = Object.keys(cs);
      for(let i=0; i<keys.length; i++){
        temp.push({
          invoiceno: i+1,
          client: cs[keys[i]].clientname,
          address: parse(cs[keys[i]].clientaddress),
          action: <button onClick={()=>{handleClient(cs[keys[i]].clientname, cs[keys[i]].clientaddress)}} className="btn btn-success btn-sm"><i className="bx bx-plus"></i>Add</button>
        });
      }
      setClients(temp);
    });
    const starCountRef2 = ref(db, 'companys');
    onValue(starCountRef2, (snapshot) => {
      const cs = snapshot.val();
      let temp = [];
      let keys = Object.keys(cs);
      for(let i=0; i<keys.length; i++){
        temp.push({
          invoiceno: i+1,
          company: cs[keys[i]].companyname,
          address: parse(cs[keys[i]].companyaddress),
          action: <button onClick={()=>{handlecompany(cs[keys[i]].companyname, cs[keys[i]].companyaddress)}} className="btn btn-success btn-sm"><i className="bx bx-plus"></i>Add</button>
        });
      }
      setcompanys(temp);
    });
    const starCountRef3 = ref(db, 'banks');
    onValue(starCountRef3, (snapshot) => {
      const cs = snapshot.val();
      let temp = [];
      let keys = Object.keys(cs);
      for(let i=0; i<keys.length; i++){
        temp.push({
          invoiceno: i+1,
          bank: cs[keys[i]].bankname,
          details: parse(cs[keys[i]].bankaddress),
          action: <button onClick={()=>{handlebankdata(cs[keys[i]].bankname, cs[keys[i]].bankaddress)}} className="btn btn-success btn-sm"><i className="bx bx-plus"></i>Add</button>
        });
      }
      setbanks(temp);
    });
    const starCountRef4 = ref(db, 'labels');
    onValue(starCountRef4, (snapshot) => {
      const cs = snapshot.val();
      let temp = [];
      if(cs){
        let keys = Object.keys(cs);
        for(let i=0; i<keys.length; i++){
          temp.push({
            invoiceno: i+1,
            label: parse(cs[keys[i]].newlabel),
            action: <button onClick={()=>{handlelabel(cs[keys[i]].newlabel)}} className="btn btn-success btn-sm"><i className="bx bx-plus"></i>Add</button>
          });
        }
      }
      setalllabels(temp);
    });
  }, []);

  //Print the Invoice
  const printInvoice = () => {
    setprinting(true);
    const printContents = document.getElementById('printablediv').innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    setprinting(false);
  };

  const updateInvoice = ()=>{
    let d = new Date();
    set(ref(db, 'invoices/' + params.id), {
      client,
      clientaddress2,
      company,
      companyaddress2,
      bank,
      bankdetails,
      labels,
      invoiceDetail,
      status:"Draft"
    });
    window.history.back();
  };

  const updateissueInvoice = ()=>{
    let d = new Date();
    set(ref(db, 'invoices/' + params.id), {
      client,
      clientaddress2,
      company,
      companyaddress2,
      bank,
      bankdetails,
      labels,
      invoiceDetail,
      status:"Issued"
    });
    window.history.back();
  };

  const saveInvoice = ()=>{
    let d = new Date();
    set(ref(db, 'invoices/' + d.getTime()), {
      client,
      clientaddress2,
      company,
      companyaddress2,
      bank,
      bankdetails,
      labels,
      invoiceDetail,
      status:"Draft"
    });
    window.history.back();
  };

  const issueInvoice = ()=>{
    let d = new Date();
    set(ref(db, 'invoices/' + d.getTime()), {
      client,
      clientaddress2,
      company,
      companyaddress2,
      bank,
      bankdetails,
      labels,
      invoiceDetail,
      status:"Issued"
    });
    window.history.back();
  };

  function tog_standard() {
    let temp = !modal_standard;
    setmodal_standard(temp);
    removeBodyCss();
  }

  function tog_standard2() {
    let temp = !modal_standard2;
    setmodal_standard2(temp);
    removeBodyCss();
  }

  function tog_standard3() {
    let temp = !modal_standard3;
    setmodal_standard3(temp);
    removeBodyCss();
  }

  function tog_standard4() {
    let temp = !modal_standard4;
    setmodal_standard4(temp);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  let handleClient = (name, address)=>{
    setclientaddress(address);
    setclient(name);
    setmodal_standard(false);
  }

  let handlecompany = (name, address)=>{
    setcompany(name);
    setcompanyaddress2(address);
    setmodal_standard2(false);
  }

  let handlebankdata = (name, address)=>{
    setbank(name);
    setbankdetails(address);
    setmodal_standard3(false);
  }

  let handlelabel = (lb)=>{
    setlabels(lb);
    setmodal_standard4(false);
  }
  
  const addclient = ()=>{
    setaddclient(!isaddclient);
  }

  const addNewClient = ()=>{
    let d = new Date();
    set(ref(db, 'clients/' + d.getTime()), {
      clientname,
      clientaddress
    });
    setclientname("");
    setClientaddress("");
    setaddclient(false);
  }
  
  const addcompany = ()=>{
    setaddcompany(!isaddcompany);
  }

  const addNewcompany = ()=>{
    let d = new Date();
    set(ref(db, 'companys/' + d.getTime()), {
      companyname,
      companyaddress
    });
    setcompanyname("");
    setcompanyaddress("");
    setaddcompany(false);
  }
  
  const addbank = ()=>{
    setaddbank(!isaddbank);
  }
  
  const addnewlabel = ()=>{
    let d = new Date();
    set(ref(db, 'labels/' + d.getTime()), {
      newlabel
    });
    setnewlabel("");
    setislabel(false);
  }

  const addNewbank = ()=>{
    let d = new Date();
    set(ref(db, 'banks/' + d.getTime()), {
      bankname,
      bankaddress
    });
    setbankname("");
    setbankaddress("");
    setaddbank(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {params && params.id ?
            <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
            :
            <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
          }

          {!isEmpty(invoiceDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody id="printablediv" className="p-5">
                    <div id="printabledivbox">
                      <div className="invoice-title d-flex justify-content-between">
                        <div className="mb-4">
                          <img src={logo} alt="logo-dark" className="logo-dark-element" height="80" />
                          <img src={logoLight} alt="logo-light" className="logo-light-element" height="80" />
                        </div>
                        <div style={{ textAlign:"right" }}>
                          <h4 style={{ fontSize:"1.75rem" }}>
                            Invoice: {invoiceinput?
                              <input type="text" style={{ border: 0, borderBottom:"solid 1px #cbced4" }} onBlur={()=>{setinvoiceinput(false)}} value={invoiceDetail.invoiceno} onChange={(e)=>{
                                setInvoiceDetails({
                                  ...invoiceDetail,
                                  invoiceno: e.target.value,
                                });
                              }}/>
                            :
                              <span style={{ borderBottom:"solid 1px #cbced4", cursor:"pointer" }} onClick={()=>{setinvoiceinput(true)}}>{invoiceDetail.invoiceno}</span>
                            }
                            
                          </h4>
                          <h4 className="font-size-16" style={{ fontWeight:"400" }}>
                            Issued At: <span style={{ borderBottom:"solid 1px #cbced4", cursor:"pointer" }} onClick={()=>{setmodal_standard5(true)}}>{invoiceDetail.issuedatedisplay}</span>
                          </h4>
                          <h4 className="font-size-16" style={{ fontWeight:"400" }}>
                            Due At: <span style={{ borderBottom:"solid 1px #cbced4", cursor:"pointer" }} onClick={()=>{setmodal_standard6(true)}}>{invoiceDetail.duedatedisplay}</span>
                          </h4>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address onClick={tog_standard} style={{ cursor:"pointer" }}>
                            {
                              client ?
                                <>
                                  <h6><strong>{client}</strong></h6>
                                  {parse(clientaddress2)}
                                </>
                                :
                                <>
                                  {!printing?
                                    <button className="btn btn-light">Select Client</button>
                                  :null}
                                </>
                            }

                          </address>
                        </Col>
                        <Col xs="6" className="text-end">
                          <address onClick={tog_standard2} style={{ cursor:"pointer" }}>
                            {
                              company ?
                                <>
                                  <h6><strong>{company}</strong></h6>
                                  {parse(companyaddress2)}
                                </>
                                :
                                <>
                                  {!printing?
                                    <button className="btn btn-light">Select Company</button>
                                  :null}
                                </>
                            }
                          </address>
                        </Col>
                      </Row>
                      <div className="py-2 mt-3">
                        <p>
                          {noteinput?
                            <input type="text" style={{ border: 0, borderBottom:"solid 1px #cbced4" }} onBlur={()=>{setnoteinput(false)}} value={invoiceDetail.note} onChange={(e)=>{
                              setInvoiceDetails({
                                ...invoiceDetail,
                                note: e.target.value,
                              });
                            }}/>
                          :
                            <span style={{ borderBottom:"solid 1px #cbced4", cursor:"pointer" }} onClick={()=>{setnoteinput(true)}}>{invoiceDetail.note}</span>
                          }
                        </p>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th style={{ fontWeight:"400", width:"60%" }}><strong>Item</strong></th>
                              <th style={{ fontWeight:"400", width:"10%" }} className="text-end"><strong>Quantity</strong></th>
                              <th style={{ fontWeight:"400", width:"15%" }} className="text-end"><strong>Price</strong></th>
                              <th style={{ fontWeight:"400", width:"15%" }} className="text-end"><strong>Sum</strong></th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(
                              invoiceDetail.item,
                              (item, key) => (
                                <tr key={key}>
                                  <td>
                                    <input type="text" className="no-print-border" style={{ border:"0px", width:"100%", }} placeholder="Item Name" value={item.title} onChange={(e)=>{
                                      let crntitem = item;
                                      crntitem = {
                                        ...crntitem,
                                        title: e.target.value
                                      };
                                      let temp = [];
                                      for(let i=0; i<invoiceDetail.item.length; i++){
                                        if(i==key){
                                          temp.push(crntitem);
                                        }else{
                                          temp.push(invoiceDetail.item[i]);
                                        }
                                      }
                                      setInvoiceDetails({
                                        ...invoiceDetail,
                                        item: temp
                                      })
                                    }}/>
                                  </td>
                                  <td className="text-end">
                                    <input type="text" className="no-print-border text-end" style={{ border:"0px" }} value={item.qty} onChange={(e)=>{
                                      let crntitem = item;
                                      crntitem = {
                                        ...crntitem,
                                        qty: e.target.value,
                                        sum: parseInt(e.target.value)*parseInt(crntitem.price)
                                      };
                                      let temp = [];
                                      let total = 0;
                                      for(let i=0; i<invoiceDetail.item.length; i++){
                                        if(i==key){
                                          temp.push(crntitem);
                                          total += crntitem.sum;
                                        }else{
                                          temp.push(invoiceDetail.item[i]);
                                          total += invoiceDetail.item[i].sum;
                                        }
                                      }
                                      setInvoiceDetails({
                                        ...invoiceDetail,
                                        item: temp,
                                        total: total
                                      })
                                    }}/>
                                  </td>
                                  <td className="text-end">
                                    <input type="text" className="no-print-border text-end" style={{ border:"0px" }} value={item.price} onChange={(e)=>{
                                      let crntitem = item;
                                      crntitem = {
                                        ...crntitem,
                                        price: e.target.value,
                                        sum: parseInt(e.target.value)*parseInt(crntitem.qty)
                                      };
                                      let temp = [];
                                      let total = 0;
                                      for(let i=0; i<invoiceDetail.item.length; i++){
                                        if(i==key){
                                          temp.push(crntitem);
                                          total += crntitem.sum;
                                        }else{
                                          temp.push(invoiceDetail.item[i]);
                                          total += invoiceDetail.item[i].sum;
                                        }
                                      }
                                      setInvoiceDetails({
                                        ...invoiceDetail,
                                        item: temp,
                                        total: total
                                      })
                                    }}/>
                                  </td>
                                  <td className="text-end">
                                    {item.sum}
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td colSpan="2" className="border-0 text-start">
                                {!printing?
                                  <button className="btn btn-light hide-on-print" onClick={()=>{
                                    let temp = invoiceDetail.item;
                                    temp.push({
                                      title: "",
                                      qty: "0",
                                      price: "0",
                                      sum: "0"
                                    });
                                    setInvoiceDetails({
                                      ...invoiceDetail,
                                      item: temp
                                    })
                                  }}>+</button>
                                :null}
                              </td>
                              <td className="border-0 text-end">
                              </td>
                            </tr>
                            <tr className="pt-4">
                              <td colSpan="3" className="border-0 text-end">
                                <strong>Total : &nbsp;
                                  {currencyinput?
                                    <input type="text" style={{ border: 0, borderBottom:"solid 1px #cbced4" }} onBlur={()=>{setcurrencyinput(false)}} value={invoiceDetail.currency} onChange={(e)=>{
                                      setInvoiceDetails({
                                        ...invoiceDetail,
                                        currency: e.target.value,
                                      });
                                    }}/>
                                  :
                                    <span style={{ borderBottom:"solid 1px #cbced4", cursor:"pointer" }} onClick={()=>{setcurrencyinput(true)}}>{invoiceDetail.currency}</span>
                                  }
                                </strong>
                              </td>
                              <td className="border-0 text-end">
                                  {invoiceDetail.total}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <Row>
                        <Col xs="6">
                          <address onClick={tog_standard3} style={{ cursor:"pointer" }}>
                            {
                              bank ?
                                <>
                                  <h6><strong>{bank}</strong></h6>
                                  {parse(bankdetails)}
                                </>
                                :
                                <button className="btn btn-light">Select Bank</button>
                            }

                          </address>
                        </Col>
                        <Col xs="6" className="text-end">
                          <address onClick={tog_standard4} style={{ cursor:"pointer" }}>
                            {
                              labels ?
                                <>
                                  {parse(labels)}
                                </>
                                :
                                <button className="btn btn-light" onClick={tog_standard4}>Select Label</button>
                            }
                          </address>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardBody>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        {params.id?
                          <>
                            <button to="#" onClick={updateInvoice} className="btn btn-primary w-md  me-2">
                              Update
                            </button>
                            {
                              status=="Draft"?
                                <button to="#" onClick={updateissueInvoice} className="btn btn-primary w-md ">
                                  Issue
                                </button>
                              :null
                            }
                          </>
                        :
                        <>
                          <button to="#" onClick={saveInvoice} className="btn btn-primary w-md  me-2">
                            Save
                          </button>
                          <button to="#" onClick={issueInvoice} className="btn btn-primary w-md ">
                            Issue
                          </button>
                        </>}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Select Client
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button onClick={addclient} className="btn btn-light">{isaddclient?<i className="bx bx-chevron-up"></i>:<i className="bx bx-plus"></i>} Add Client</button>
              </div>
              {
                isaddclient?
                  <div>
                    <input type="text" placeholder="Enter Client Name" className="form-control my-4" value={clientname} onChange={(e)=>{setclientname(e.target.value)}}/>
                    <h6>Enter Client Address</h6>
                    <ReactQuill theme="snow" value={clientaddress} onChange={setClientaddress} />
                    <div className="text-center pt-4">
                      <button className="btn btn-outline-primary" onClick={addNewClient}>Add</button>
                    </div>
                  </div>
                :null
              }
              <TableContainer
                columns={columns}
                data={clients}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />
            </div>
          </Modal>
          <Modal
            isOpen={modal_standard2}
            toggle={() => {
              tog_standard2();
            }}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Select Company
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard2(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button onClick={addcompany} className="btn btn-light">{isaddcompany?<i className="bx bx-chevron-up"></i>:<i className="bx bx-plus"></i>} Add Company</button>
              </div>
              {
                isaddcompany?
                  <div>
                    <input type="text" placeholder="Enter Company Name" className="form-control my-4" value={companyname} onChange={(e)=>{setcompanyname(e.target.value)}}/>
                    <h6>Enter Company Address</h6>
                    <ReactQuill theme="snow" value={companyaddress} onChange={setcompanyaddress} />
                    <div className="text-center pt-4">
                      <button className="btn btn-outline-primary" onClick={addNewcompany}>Add</button>
                    </div>
                  </div>
                :null
              }
              <TableContainer
                columns={columns2}
                data={companys}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />
            </div>
          </Modal>
          <Modal
            isOpen={modal_standard3}
            toggle={() => {
              tog_standard3();
            }}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Select Bank
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard3(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button onClick={addbank} className="btn btn-light">{isaddbank?<i className="bx bx-chevron-up"></i>:<i className="bx bx-plus"></i>} Add Bank</button>
              </div>
              {
                isaddbank?
                  <div>
                    <input type="text" placeholder="Enter Bank Name" className="form-control my-4" value={bankname} onChange={(e)=>{setbankname(e.target.value)}}/>
                    <h6>Enter Bank Details</h6>
                    <ReactQuill theme="snow" value={bankaddress} onChange={setbankaddress} />
                    <div className="text-center pt-4">
                      <button className="btn btn-outline-primary" onClick={addNewbank}>Add</button>
                    </div>
                  </div>
                :null
              }
              <TableContainer
                columns={columns3}
                data={banks}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />
            </div>
          </Modal>
          <Modal
            isOpen={modal_standard4}
            toggle={() => {
              tog_standard4();
            }}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Select Label
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard4(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-end">
                <button onClick={()=>{setislabel(!islabel)}} className="btn btn-light">{islabel?<i className="bx bx-chevron-up"></i>:<i className="bx bx-plus"></i>} Add Bank</button>
              </div>
              {
                islabel?
                  <div>
                    <h6>Enter Labels</h6>
                    <ReactQuill theme="snow" value={newlabel} onChange={setnewlabel} />
                    <div className="text-center pt-4">
                      <button className="btn btn-outline-primary" onClick={addnewlabel}>Add</button>
                    </div>
                  </div>
                :null
              }
              <TableContainer
                columns={columns4}
                data={alllabels}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />
            </div>
          </Modal>
          <Modal
            isOpen={modal_standard5}
            toggle={() => {
              tog_standard5();
            }}
            size="sm"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Issued At
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard5(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="date" className="form-control" onBlur={()=>{setmodal_standard5(false)}} value={invoiceDetail.issuedate} onChange={(e)=>{
                let d = new Date(e.target.value);
                let newdate = d.getDate().toString();
                if(d.getMonth() == 0){
                  newdate = newdate + ". Jan ";
                }else if(d.getMonth() == 1){
                  newdate = newdate + ". Feb ";
                }else if(d.getMonth() == 2){
                  newdate = newdate + ". Mar ";
                }else if(d.getMonth() == 3){
                  newdate = newdate + ". Apr ";
                }else if(d.getMonth() == 4){
                  newdate = newdate + ". May ";
                }else if(d.getMonth() == 5){
                  newdate = newdate + ". Jun ";
                }else if(d.getMonth() == 6){
                  newdate = newdate + ". Jul ";
                }else if(d.getMonth() == 7){
                  newdate = newdate + ". Aug ";
                }else if(d.getMonth() == 8){
                  newdate = newdate + ". Sep ";
                }else if(d.getMonth() == 9){
                  newdate = newdate + ". Oct ";
                }else if(d.getMonth() == 10){
                  newdate = newdate + ". Nov ";
                }else if(d.getMonth() == 11){
                  newdate = newdate + ". Dec ";
                }
                newdate = newdate + d.getFullYear().toString();
                setInvoiceDetails({
                  ...invoiceDetail,
                  issuedate: e.target.value,
                  issuedatedisplay: newdate,
                });
                setmodal_standard5(false);
              }}/>
            </div>
          </Modal>
          <Modal
            isOpen={modal_standard6}
            toggle={() => {
              tog_standard6();
            }}
            size="sm"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Due At
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard6(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input type="date" className="form-control" onBlur={()=>{setmodal_standard6(false)}} value={invoiceDetail.issuedate} onChange={(e)=>{
                let d = new Date(e.target.value);
                let newdate = d.getDate().toString();
                if(d.getMonth() == 0){
                  newdate = newdate + ". Jan ";
                }else if(d.getMonth() == 1){
                  newdate = newdate + ". Feb ";
                }else if(d.getMonth() == 2){
                  newdate = newdate + ". Mar ";
                }else if(d.getMonth() == 3){
                  newdate = newdate + ". Apr ";
                }else if(d.getMonth() == 4){
                  newdate = newdate + ". May ";
                }else if(d.getMonth() == 5){
                  newdate = newdate + ". Jun ";
                }else if(d.getMonth() == 6){
                  newdate = newdate + ". Jul ";
                }else if(d.getMonth() == 7){
                  newdate = newdate + ". Aug ";
                }else if(d.getMonth() == 8){
                  newdate = newdate + ". Sep ";
                }else if(d.getMonth() == 9){
                  newdate = newdate + ". Oct ";
                }else if(d.getMonth() == 10){
                  newdate = newdate + ". Nov ";
                }else if(d.getMonth() == 11){
                  newdate = newdate + ". Dec ";
                }
                newdate = newdate + d.getFullYear().toString();
                setInvoiceDetails({
                  ...invoiceDetail,
                  duedate: e.target.value,
                  duedatedisplay: newdate,
                });
                setmodal_standard6(false);
              }}/>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {
  match: PropTypes.any,
};

export default withRouter(InvoiceDetail);
